

export default {
    layout: "download",

    data() {
        return {
            progress: 0,
            valid: true
        }
    },

    mounted() {
        const filename = this.$route.params.filename.replaceAll("+", "%20");
        const uri = `https://${process.env.fileGateway}/downloads/packaged/${this.$route.params.id}/${filename}`;

        this.$axios
            .get(uri, {
                //next line to make .zip files work
                responseType: "arraybuffer",
                onDownloadProgress: (e) => {
                    this.progress = Math.round((e.loaded / e.total) * 100);
                },
            })
            .then((response) => {
                // handle success
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                let dlName = filename.replaceAll("%20", " ");
                link.setAttribute("download", dlName); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.done = true;
            })
            .catch((err) => {
                this.valid = false;
            });
    },

    methods: {
        navigateHome() {
            this.$router.push("/");
        },
    },
};
