// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_h1j2z{align-items:center;height:100vh;justify-content:center;outline:solid 1px var(--color-black-200);width:100vw}.container_h1j2z,.inner_4eaY\\+{display:flex;flex-direction:column}.inner_4eaY\\+{width:32rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_h1j2z",
	"inner": "inner_4eaY+"
};
module.exports = ___CSS_LOADER_EXPORT___;
